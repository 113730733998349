<template>
	<div class="newsList">
		<div class="listingTitle">
			<div>
				<span>你的位置：</span>
				<router-link :to="{name:'Home'}">
					<span class="toHome">首页</span>
				</router-link>
				<span>></span>
				<span>新闻资讯</span>
			</div>
			<div class="linkBox">
				<div class="newsLink" @click="chengeCulture">公司文化</div>
				<div class="newsLink" @click="chengeNews">公司新闻</div>
			</div>
		</div>
		<div class="newsBox">
			<!-- v-show="isculture" -->
			<div class="singleNews" v-for="(item,index) in newsList" :key="index">
				<div>
					<img :src="item.pic" alt="">
				</div>
				<div class="newsBulletin">
					<div class="newsTitle">
						<p @click="toDetail(item.title,item.cid)">{{item.title}}</p>
						<p>{{item.createTime}}</p>
					</div>
					<div class="newsText">
						<p>{{item.summary}}</p>
						<div @click="toDetail(item.title,item.cid)">MORE</div>
					</div>
				</div>
			</div>
		</div>
		<Page :total="numTotal" />
	</div>
</template>

<script>
	import api from '../../services/api.js'
	export default {
		name: 'newsLIst',
		data() {
			return {
				isculture: true,
				newsList: [],
				numTotal: 0,
			}
		},
		mounted() {
			this.chengeCulture()
		},
		methods: {
			getNewsList() {
				this.newsList = []
				let prams = {}
				if (this.isculture == true) {
					prams = {
						type: 'culture',
						curPage: 1,
						pageSize: 5
					}
				} else {
					prams = {
						type: 'news',
						curPage: 1,
						pageSize: 5
					}
				}
				this.$axios({
					url: api.newsList,
					method: 'post',
					params: prams
				}).then((data) => {
					data.data.forEach((item, index) => {
						item.pic = api.url + item.pic
						this.newsList.push(item)
						this.numTotal = this.newsList.length
					})
				})
			},
			toDetail(title, cid) {
				let prams = ''
				let picList = []
				if (this.isculture == true) {
					prams = 'culture/'
				} else {
					prams = 'news/'

				}
				this.$axios({
					url: api.newsDetail + prams + cid,
					method: 'get',
				}).then((data) => {
					if (data.data.news.contentImages) {
						data.data.news.contentImages.forEach((item, index) => {
							item = api.url + item
							picList.push(item)
						})
						data.data.news.contentImages = picList
					}
					data.data.news.pic = api.url + data.data.news.pic
					data.data.news.prePic = api.url + data.data.news.prePic
					data.data.news.nextPic = api.url + data.data.news.nextPic
					let params = data.data.news
					params["NewsFontList"] = data.data.news.content.split("<br>")
					this.$emit('toDetail', params)
				})
			},
			chengeNews() {
				let newsLink = document.querySelectorAll(".newsLink")
				newsLink[0].style.cssText = "background: white; color: #959595;"
				newsLink[1].style.cssText = "background: linear-gradient(45deg, #0067C7, #00C0FF); color: white;"
				this.isculture = false
				this.getNewsList()
			},
			chengeCulture() {
				let newsLink = document.querySelectorAll(".newsLink")
				newsLink[0].style.cssText = "background: linear-gradient(45deg, #0067C7, #00C0FF); color: white;"
				newsLink[1].style.cssText = "background: white; color: #959595;"
				this.isculture = true
				this.getNewsList()
			}
		}
	}
</script>

<style scoped lang="scss">
	.newsList {
		width: 1903px;
		padding: 40px 0 0 378px;

		.listingTitle {
			width: 1159px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-size: 16px;

			>div {
				a {
					color: #959595 !important;
				}

				.toHome {
					cursor: pointer;
					color: #959595 !important;
				}

				.toHome:hover {
					cursor: pointer;
					color: #0067C7 !important;
				}

				.toHome:active {
					cursor: pointer;
					color: #00396F !important;
				}

				span:nth-of-type(2) {
					margin: 0 10px;
				}

				span:last-of-type {
					color: #006DB5;
				}
			}

			.linkBox {
				width: 196px;
				display: flex;
				justify-content: space-between;
				align-items: cemter;

				.newsLink {
					width: 92px;
					height: 32px;
					cursor: pointer;
					border: 1px solid #DDDDDD;
					text-align: center;
					line-height: 32px;
					color: #959595;
				}

				.newsLink:hover {
					color: white !important;
					background: linear-gradient(45deg, #558EC4, #6ADAFF) !important;
				}

				.newsLink:active {
					color: white !important;
					background: linear-gradient(45deg, #0067C7, #00C0FF) !important;
				}
			}
		}

		.newsBox {
			margin: 32px 0 19px 0;

			.singleNews {
				width: 1184px;
				display: flex;
				justify-content: space-between;
				border-bottom: 1px solid #EDEDED;
				padding: 20px 0;

				>div:first-of-type {
					img {
						width: 270px;
						height: 140px;
					}
				}

				.newsBulletin {
					padding: 13px 0 0 39px;

					.newsTitle {
						display: flex;
						justify-content: space-between;
						align-items: center;

						>p:first-of-type {
							font-size: 18px;
							color: #101212;
							font-weight: 800;
						}

						>p:first-of-type:hover {
							color: #0067C7;
							cursor: pointer;
						}

						>p:first-of-type:active {
							cursor: pointer;
							color: #00396F !important;
						}

						>p:last-of-type {
							font-size: 14px;
							color: #959595;
						}
					}

					.newsText {
						display: flex;
						padding-top: 24px;

						>p {
							width: 750px;
							font-size: 16px;
							color: #959595;
							padding-right: 38px;
						}

						>div {
							width: 87px;
							height: 33px;
							margin-top: 40px;
							border: 1px solid #E8E8E8;
							text-align: center;
							line-height: 33px;
							font-size: 14px;
						}

						>div:hover {
							color: #0067C7;
							cursor: pointer;
						}

						>div:active {
							cursor: pointer;
							color: #00396F !important;
						}
					}
				}
			}
		}

		.ivu-page {
			margin-bottom: 40px;
			width: 1183px;
			text-align: center;
		}
	}
</style>
