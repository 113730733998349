<template>
	<div class="news">
		<top @openWindow="openWindow" @pageid="toOtherPage"></top>
		<div class="topPic">
			<img src="../../assets/img/news/探索我们的企业使命.png" alt="">
		</div>
		<newsList v-show="!isdetail" @toDetail="changeDetail"></newsList>
		<newsDetail v-show="isdetail" :detail-data="detailData" @toPage="changePage" @pageid="toOtherPage"></newsDetail>
		<recruit v-show="showRecruit" @close="closeWindow"></recruit>
		<bottom @open="openWindow" @pageid="toOtherPage"></bottom>
	</div>
</template>
<script>
	import top from "../home/top.vue"
	import bottom from "../home/bottom.vue"
	import recruit from "../recruitmentInformation/recruit.vue"
	import newsList from "./newsList.vue"
	import newsDetail from "./newsDetail.vue"
	import api from '../../services/api.js'
	export default {
		name: 'news',
		data() {
			return {
				value1: 0,
				value2: 0,
				value3: 0,
				startTime: null,
				timer: null,
				toRight: false,
				transNum: 0,
				pageIds: 0,
				isnews: false,
				showRecruit: false,
				isdetail: false,
				detailData: {}
			}
		},
		components: {
			top,
			bottom,
			recruit,
			newsList,
			newsDetail
		},
		mounted() {
			this.switchs()
			if (this.$route.query.toDetail != undefined && this.isdetail != true) {
				this.getDetailData(this.$route.query)
			}
		},
		updated() {
			window.scroll(0, 0);
		},
		beforeRouteLeave(to, from, next) {
			if (this.isdetail == true && this.pageIds == 0) {
				this.changePage(false)
				next(false);
			} else {
				next();
			}
		},
		methods: {
			getDetailData(data) {
				let prams = ''
				let picList = []
				if (data.isCulture == true) {
					prams = 'culture/'
				} else {
					prams = 'news/'

				}
				this.$axios({
					url: api.newsDetail + prams + data.cid,
					method: 'get',
				}).then((end) => {
					if (end.data.news.contentImages) {
						end.data.news.contentImages.forEach((item, index) => {
							item = api.url + item
							picList.push(item)
						})
						end.data.news.contentImages = picList
					}
					end.data.news.pic = api.url + end.data.news.pic
					end.data.news.prePic = api.url + end.data.news.prePic
					end.data.news.nextPic = api.url + end.data.news.nextPic
					let params = end.data.news
					params["NewsFontList"] = end.data.news.content.split("<br>")
					this.detailData = params
					this.isdetail = true
				})
			},
			changeDetail(data) {
				this.detailData = data
				this.isdetail = true
			},
			changePage(data) {
				this.isdetail = data
			},
			toOtherPage(id) {
				this.pageIds = id
			},
			openWindow() {
				this.showRecruit = true
			},
			closeWindow(data) {
				this.showRecruit = false
			},
			switchs() {
				let titleFont = document.querySelectorAll(".titleFont")
				let switchBar = document.querySelectorAll(".switchBar")
				titleFont.forEach((item, index) => {
					item.style.cssText = "color: #101212;"
				})
				switchBar.forEach((item, index) => {
					item.style.cssText = " visibility: hidden;"
				})
				switchBar[2].style.cssText = " visibility: visible;"
				titleFont[2].style.cssText = "color: #0079D3;"
			}
		}
	}
</script>
<style scoped lang="scss">
	.news {
		.topPic {
			img {
				width: 1903px;
				height: 380px;
			}
		}
	}
</style>
